import React, { useContext } from 'react';
import { Context } from './../state/store.component';

export const Patefon = () => {
    const [state, ] = useContext(Context);

    return (
    <>
        <div className="logo">
               הערוצים הדיגיטליים של <a href="http://www.100fm.co.il/" target="_blank" rel="noreferrer"><img alt="100fm" height="33" src="/img/100fm.png" width="101"/></a>
        </div>
        <div className={"patefon " + (state.playing ? "active" : "")} id="patefon">
            <div className="pinter"><img alt="pointer" src="img/pointer.png"/></div>
            <div className="base"><img alt="base" src="img/base.png"/></div>
            <div className="cover" id="station_logo">
                <div className="mask"><img src={state.selected.cover.replace("http://", "https://")} alt={state.selected.name}/></div>
            </div>
            <img className="recored" src="img/record.png" alt="record"/>
        </div>
    </>
    );
};