import React, { useEffect, useContext } from 'react';
import { StationBlock } from './block.component';
import { Context } from './../state/store.component';
import CircularProgress from '@material-ui/core/CircularProgress';

export const StationPicker = () => {
    const [state, dispatch] = useContext(Context);

    useEffect(() => {
        fetch('https://digital.100fm.co.il/app/')
            .then(res => res.json())
            .then(res => {
                let station = res.stations[0];
                dispatch({type: 'selected', payload: station});
                dispatch({type: 'meta', payload: { title: station.name, artist: "" }});
                dispatch({type: 'stations', payload: res.stations});
            })
            .catch(error => {
                dispatch({type: 'SET_ERROR', payload: error});
            });
    }, [dispatch]);

    let stations = <div className="progress"><CircularProgress /></div>;

    if (state.error) {
        stations = <p>Something went wrong: <span>{state.error}</span></p>;
    }

    if (!state.error && state.stations) {
        stations = <div className="picker">
            {state.popular.length === 0 ? <div/> : <StationBlock title="המועדפים שלי" selected={state.selected} stations={state.stations.filter(item => state.popular.includes(item.slug) )}/>}                
            <StationBlock title="ערוצים פופולריים" selected={state.selected} stations={state.stations.filter(item => item.popular === "true" && !state.popular.includes(item.slug) )}/>
            <StationBlock title="ערוצים דיגיטלים" selected={state.selected} stations={state.stations.filter(item => item.popular === "false" && item.content === "false" && !state.popular.includes(item.slug) )}/>
            <StationBlock title="ערוצי תוכן" selected={state.selected} stations={state.stations.filter(item => item.content === "true" )}/>
        </div>;
    }

    return (stations);
}