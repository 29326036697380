import React from 'react';
import { StationTile } from './tile.component';

export const StationBlock = (props) => {
    return (
    <div className="block">
        <h1>{props.title}</h1>
        {props.stations.map(station => (
            <StationTile key={station.slug} station={station} active={station.slug === props.selected.slug}/>
        ))}
        <div className="clear"/>
    </div>
    );
}