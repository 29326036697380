import React, { useContext } from 'react';
import { Context } from './../state/store.component';
import { Like } from './like.component';

export const Header = () => {
    const [state, ] = useContext(Context);

    if (state.selected == null) {
    return (<div/>);
    }

    return (
    <>
        <div className="header">
            <div className="fb">
                <Like/>
            </div>
            <div className="station_description">
				<h2>{state.selected ? state.selected.description : "ערוצי המוזיקה של רדיוס 100FM מחכים רק לכם. האזנה נעימה!"}</h2>
            </div>
        </div>
    </>
    );
};