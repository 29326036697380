import React from 'react';

export const Mobile = () => {
    return (
    <>
        <div className="mobile">
            <p className="logo">
							הערוצים הדיגיטליים של <br/>
            <img alt='Download on the App Store' src='img/100fm.png'/>
            </p>
            <h2>חכו שתראו מה יש לאפליקציה שלנו להציע לכם!</h2>
		    <p>
                <a href="https://itunes.apple.com/il/app/rdyw-rdyws-100fm-mwzyqh-zh-hkl/id946941095?mt=8">
                <img alt='Download on the App Store' className="appstore" src='img/appstore.png'/>
                </a>
            </p>
		    <p>
                <a href='https://play.google.com/store/apps/details?id=com.nxcast.stations.il.fm100&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
            </p>
        </div>
    </>
    );
};