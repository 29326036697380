import React from 'react';
import Store from './components/state/store.component';
import { AudioPlayer } from './components/audio/audioPlayer.component';
import { StationPicker } from './components/station/picker.component';
import { Header } from './components/ui/header.component';
import { useMediaQuery } from 'react-responsive'
import ReactGA from 'react-ga';
import './App.scss';
import { Mobile } from './components/ui/mobile.component';

const App = () => {
  ReactGA.initialize('UA-2092123-4');
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  return (
      <Store>
        {isTabletOrMobile && <Mobile/>}
        <div className="App">
          <Header/>
          <StationPicker/>
          <AudioPlayer/>
        </div>
      </Store>
  );
};

export default App;
