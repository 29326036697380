import React, {createContext, useReducer} from "react";
import Reducer from './reducer.component'


const initialState = {
    stations: null,
    selected: null,
    alt: 0,
    volume: parseFloat(localStorage.getItem('audioVolume') || '0.5'),
    meta: { title: "Radius 100FM", artist: "" },
    playing: false,
    popular: popular()
};

function popular() {
    var json = JSON.parse(localStorage.getItem('latest') || '{}');
    var list = [];
    for (var key in json) { list.push({ key: key, val: json[key] }); };
    
    list.sort((a, b) => b.val - a.val);

    return list.slice(0, 6).map(item => { return item.key; });
}

const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;


// https://codeburst.io/global-state-with-react-hooks-and-context-api-87019cc4f2cf