import React from 'react';

export class Like extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          url: props.url,
      }
    }
  
    handleChangePage() {
      let likeBtn = document.createElement('div');
      likeBtn.className = "fb-like";
      likeBtn.setAttribute("data-href", "http://digital.100fm.co.il/");
      likeBtn.setAttribute("data-width", "");
      likeBtn.setAttribute("data-layout", "box_count");
      likeBtn.setAttribute("data-action", "like");
      likeBtn.setAttribute("data-size", "large");
      likeBtn.setAttribute("data-show-faces", "true");
      likeBtn.setAttribute("data-share", "true");
  
      let likePanel = document.getElementById("like-panel");
    //   likePanel.removeChild(likePanel.childNodes[0]);
      likePanel.appendChild(likeBtn);
  
      window.FB.XFBML.parse(likePanel)
      this.setState({ url: this.props.url });
    }
  
    componentDidMount() {
      this.handleChangePage();
    }
  
    render() {
      if(this.props.url !== this.state.url) 
        this.handleChangePage();
  
      return <div id="like-panel">
        {this.props.facebookLoaded
          ? <div className="fb-like" data-href="http://digital.100fm.co.il/" data-width="" data-layout="box_count" data-action="like" data-size="large" data-show-faces="true" data-share="true" />
          : null}
      </div>;
    }
  }