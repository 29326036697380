import React, { useContext } from 'react';
import { Context } from './../state/store.component';
import ReactGA from 'react-ga';

export const StationTile = (props) => {
    const [, dispatch] = useContext(Context);

    function handleClick() {
        let station = props.station;
        dispatch({type: 'playing', payload: true});
        dispatch({type: 'selected', payload: station});
        dispatch({type: 'meta', payload: { title: station.name, artist: "" }});

        var json = JSON.parse(localStorage.getItem('latest') || '{}');
        if (json[station.slug]) {
            json[station.slug] += 1;
        } else {
            json[station.slug] = 1;
        }
        localStorage.setItem('latest', JSON.stringify(json));

        ReactGA.event({ category: 'audio', action: 'play', label: station.slug});
    }

    return (
    <div className="gap">
        <div className={"tile " + (props.active ? "active" : "")}>
            <a href={"#" + props.station.slug} onClick={handleClick}><img src={props.station.cover.replace("http://", "https://")} alt={props.station.name}></img></a>
        </div>
    </div>);
}