const Reducer = (state, action) => {
    switch (action.type) {
        case 'stations':
            return {
                ...state,
                stations: action.payload
            };
        case 'selected':
            return {
                ...state,
                selected: action.payload
            };
        case 'meta':
            return {
                ...state,
                meta: action.payload
            };
        case 'playing':
            return {
                ...state,
                playing: action.payload
            };
        case 'volume':
            return {
                ...state,
                volume: action.payload
            };
        case 'skip':
            let alt = (state.alt + 1) % (state.selected.sliders.length + 1)
            let selected = state.selected
            if (alt > 0) {
                selected.info = selected.sliders[alt - 1].info;
                selected.audio = selected.sliders[alt - 1].audio;
            }
            return {
                ...state,
                alt: alt,
                selected: selected
            };
            
        // case 'REMOVE_POST':
        //     return {
        //         ...state,
        //         posts: state.posts.filter(post => post.id !== action.payload)
        //     };
        // case 'SET_ERROR':
        //     return {
        //         ...state,
        //         error: action.payload
        //     };
        default:
            return state;
    }
};

export default Reducer;