import React, { useEffect, useContext, useRef } from 'react';
import { Context } from './../state/store.component';

export const Meta = () => {
    const [state, dispatch] = useContext(Context);
    const refInterval = useRef(null);

    function loadMeta(url) {
        if (url.indexOf(window.location.hostname) === -1 && window.location.hostname === "localhost") { return }
        var parser = require('fast-xml-parser');
        fetch(url.replace("http://", "https://"))
            .then(response => response.text())
            .then(text => {
                let data = parser.parse(text);
                if (data.track.artist !== "") {
                    dispatch({type: 'meta', payload: { title: data.track.name, artist: data.track.artist }});
                }
            });
    }

    useEffect(() => {
        loadMeta(state.selected.info);
        if (refInterval.current) {
            clearInterval(refInterval.current);
        }
        refInterval.current = setInterval(() => { loadMeta(state.selected.info); }, 15000);
    }, [state.selected.info]);
    

    return (<div>
        <h1>{state.meta.title}</h1>
        <h2>{state.meta.artist}</h2>
    </div>
    );
};