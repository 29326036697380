import React, { useContext } from "react";
import ReactPlayer from "react-player";

import { Context } from "./../state/store.component";
import { Meta } from "./../ui/meta.component";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilled from "@material-ui/icons/PauseCircleFilled";
import FastForward from "@material-ui/icons/FastForward";
import FastRewind from "@material-ui/icons/FastRewind";
import { Patefon } from "../ui/patefon.component";
import ParticlesBg from "particles-bg";
// import FullscreenButton from "@material-ui/icons/Fullscreen";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ReactGA from 'react-ga';

export const AudioPlayer = () => {
  const [state, dispatch] = useContext(Context);
  const handle = useFullScreenHandle();

  const playerPlay = () => {
    dispatch({ type: "playing", payload: true });
    ReactGA.event({ category: 'controls', action: 'click', label: 'play'});
  };
  const playerPause = () => {
    dispatch({ type: "playing", payload: false });
    ReactGA.event({ category: 'controls', action: 'click', label: 'pause'});
  };
  const playerToggle = () => {
    dispatch({ type: "playing", payload: !state.playing });
  };
  const playerSkip = () => {
    dispatch({ type: "skip", payload: null });
    ReactGA.event({ category: 'controls', action: 'click', label: 'skip'});
  };
  const handleChange = (event, newValue) => {
    localStorage.setItem('audioVolume', newValue.toString());

    dispatch({ type: "volume", payload: newValue });
  };

  if (state.selected == null) {
    return (<div/>);
  }

  return (
      <>
        <FullScreen handle={handle}>
          <div className="player">
            <Patefon />
            {/* <button className="fullscreen" onClick={handle.enter}>
              <FullscreenButton style={{ fontSize: 40 }} />
            </button> */}
            <div className="meta">
              <Meta />
              <div className="controls">
                <button
                  className={state.selected.sliders ? "" : "inactive"}
                  onClick={playerSkip}
                >
                  <FastRewind style={{ fontSize: 50 }} />
                </button>
                <button className="play" onClick={playerToggle}>
                  {state.playing ? (
                    <PauseCircleFilled style={{ fontSize: 90 }} />
                  ) : (
                    <PlayCircleFilled style={{ fontSize: 90 }} />
                  )}
                </button>
                <button
                  className={state.selected.sliders ? "" : "inactive"}
                  onClick={playerSkip}
                >
                  <FastForward style={{ fontSize: 50 }} />
                </button>
              </div>
              <div className="volume">
                <Grid container spacing={2}>
                  <Grid item>
                    <VolumeUp />
                  </Grid>
                  <Grid item xs>
                    <Slider
                      value={state.volume}
                      min={0}
                      max={1}
                      step={0.01}
                      onChange={handleChange}
                      aria-labelledby="continuous-slider"
                    />
                  </Grid>
                  <Grid item>
                    <VolumeDown />
                  </Grid>
                </Grid>
              </div>
            </div>
            <ReactPlayer
              onPlay={playerPlay}
              onPause={playerPause}
              playing={state.playing}
              volume={state.volume}
              url={state.selected ? state.selected.audio.replace("http://", "https://") : ""}
            />
            <ParticlesBg type="circle" bg={true} />
          </div>
        </FullScreen>
      </>
    );
};
